import { Outlet, Link } from "react-router-dom";

const ContactList = ({ contactOptionListArr }) => {
  return (
    <>
      <div className="contactlist">
        <ul className="contact-links">
          {contactOptionListArr.map((appLinkObj) => (
            <li key={appLinkObj["key"]}>
              <Link to={appLinkObj["link"]}>
                <i className={appLinkObj["icon"]}></i>
                <span className="link_name">{appLinkObj["name"]}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ContactList;
