const profileImage = require("../assets/profile-img.jpg");

const Image = () => {
  return (
    <>
        <div className="profile-img">
          <img src={profileImage} />
        </div>
    </>
  );
};

export default Image;
