import { SubmitHandler, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import axios from 'axios';

const Form = () => {
  const { register, handleSubmit, setError, setValue, reset, formState: { errors, isSubmitting } } = useForm()
  const [amountNeeded, setAmountNeeded] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async (data) => {
    setIsSubmitted(true); // Set isSubmitted to true when form is submitted
  
    try {
      setTimeout(() => {
        axios.post('https://9sk2e76uza.execute-api.us-east-1.amazonaws.com/prod', data)
      }, 1000);
  
      // Set a timeout to set isSubmitted back to false after 3 seconds
      setTimeout(() => {
        setIsSubmitted(false);
        reset(); // Reset the form
        setAmountNeeded(''); // Reset the state
        setPhoneNumber(''); // Reset the state
      }, 3000);
    } catch (error) {
      setError("root", {
        message: "An error occurred, please try again later"
      });
    }
  };

  const handleAmountChange = (event) => {
    const value = event.target.value.replace(/\D/g, ""); // remove non-digit characters
    const formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value / 100); // format as currency
    setAmountNeeded(formattedValue);
    errors["amount-needed"] = false;
  };

  const handlePhoneNumberChange = (event) => {
    let value = event.target.value.replace(/\D/g, ""); // remove non-digit characters
    if (value.length > 10) value = value.slice(0, 10); // limit to 10 digits
    let formattedValue = "";
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      formattedValue = value; // if deleting, bypass the formatting
    } else {
      if (value.length > 0) {
        formattedValue += `(${value.slice(0, 3)}) `;
        if (value.length > 3) {
          formattedValue += `${value.slice(3, 6)}`;
          if (value.length > 6) {
            formattedValue += `-${value.slice(6)}`;
          }
        }
      }
    }
    setPhoneNumber(formattedValue);
    errors["phone-number"] = false;
  };

  return (
    <>
        <form onSubmit={ handleSubmit(onSubmit) }>
            <input 
              {...register("full-name", {
                required: "Full name is required",
                validate: (value) => {
                  const nameParts = value.split(' ');
                  if (nameParts.length < 2) {
                    return "Must include both first and last name";
                  }
                  if (nameParts.some(part => part.length < 2)) {
                    return "Both first and last name must be at least 2 characters";
                  }
                    return true;
                }
              })} 
              input="text" 
              placeholder="Full Name" 
              name="full-name" 
              autoComplete="name" 
            />
            {errors['full-name'] && <div style={{ color: "red" }}>{errors['full-name'].message}</div>}
            <input 
              {...register("email", {
                required: "Email is required",
                validate: (value) => {
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  if (!emailRegex.test(value)) {
                        return "Must submit a valid email";
                    }
                    return true;
              }})}
              onChange={(event) => {
                setValue("email", event.target.value.trim());
              }}
              input="text" 
              placeholder="Email" 
              name="email" 
              autoComplete="email" 
            />
            {errors.email && <div style={{ color: "red" }}>{errors.email.message}</div>}
            <input 
              {...register("phone-number", {
                required: "Phone number is required", 
                validate: (value) => {
                  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
                  if (!phoneRegex.test(value)) {
                        return "Must submit a valid phone number";
                    }
                    return true;
              }})} 
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              type="text" 
              placeholder="Phone Number" 
              name="phone-number" 
            />
            {errors["phone-number"] && <div style={{ color: "red" }}>{errors["phone-number"].message}</div>}
            <input 
              {...register("amount-needed", {required: "Amount needed is required"})} 
              value={amountNeeded} 
              onChange={handleAmountChange} 
              type="text" 
              placeholder="Amount Needed" 
              title="Amount Needed" 
              name="amount-needed" 
              // autoComplete="on" 
            />
            {errors["amount-needed"] && <div style={{ color: "red" }}>{errors["amount-needed"].message}</div>}
            <textarea 
              {...register("message", {required: "A message is required"})} 
              placeholder="Message" 
              name="message" 
              autoComplete="on" 
            />
            {errors.message && <div style={{ color: "red" }}>{errors.message.message}</div>}
            <button disabled={isSubmitting} type="submit">{isSubmitting ? "Loading..." : "Submit"}</button>
            {isSubmitted && <div style={{ color: "red" }}>Your form is submitted! Text me, if urgent.</div>}
            {errors.root && <div style={{ color: "red" }}>{errors.root.message}</div>}
        </form>
    </>
  );
};

export default Form;
