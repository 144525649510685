import Form from './Form';

const Funding = () => {
  return (
    <>
        <div className="funding-card">
          <div className="d-flex justify-content-center align-items-center" style={{ padding: "5px 0", textAlign: "center" }}>
            <h3 style={{ margin: "0", padding: "10px" }}>Need Money for a Real Estate Project/Deal?</h3>
          </div>
          <div className="d-flex justify-content-center align-items-center" style={{ margin: "0", padding: "0"}}>
            <h6><i>I help fund deals from $1,000 up to $1MM.</i></h6>
          </div>
          <div className="d-flex justify-content-center align-items-center" style={{ margin: "0", padding: "0"}}>
            <p style={{margin: '0'}}><i>Ex: EMD, Double Close, Gap, PML, HML, +.</i></p>
          </div>
          <Form />
        </div>
    </>
  );
};

export default Funding;
