import { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/Home";
import NotFound from "./components/NotFound";

function App() {
  const contactOptionListArr = [
    // {
    //   key: '1',
    //   name: "Website Coming Soon",
    //   icon: "bx bx-collapse-alt",
    //   show: true,
    //   link: '/'
    // },
    {
      key: '2',
      name: "CSweetConsultingLLC@Gmail.Com",
      icon: "bx bxs-envelope",
      show: true,
      link: 'mailto:csweetconsultingllc@gmail.com'
    },
    {
      key: '3',
      name: "+1 (316) 712-8491",
      icon: "bx bx-phone",
      show: true,
      link: 'tel:+13167128491'
    },
    {
      key: '4',
      name: "LinkedIn Profile",
      icon: "bx bxl-linkedin",
      show: true,
      link: 'https://www.linkedin.com/in/dylancunningham'
    },
    {
      key: '5',
      name: "Facebook Profile",
      icon: "bx bxl-facebook",
      show: true,
      link: 'https://www.facebook.com/dylan.s.cunningham.7'
    },
    // {
    //   name: "Gator Community",
    //   icon: "bx bxs-dollar-circle",
    //   show: true,
    //   link: 'https://www.googleadservices.com/pagead/aclk?sa=L&ai=DChcSEwiUhdviooaFAxXeDK0GHX7QCH8YABAAGgJwdg&ase=2&gclid=CjwKCAjwte-vBhBFEiwAQSv_xdDsHwAyEIdGmnXQm9CP5dhJ6ri3VQ4fRMv93w_zEccxD_EN9xkSZxoCoi8QAvD_BwE&ohost=www.google.com&cid=CAESVeD2VDzVkE87IpHeyIC9EaRN0VzUJPCX-fJPGujiQ1MYPuF3mfTWcbtXXiaovgdpYWJB91ScSUFi_sh0aR1QeBoNxZRt0KdkDDaUsTCBrmRdU-Fd4-8&sig=AOD64_1F_r9Eo3UA61f0j7xROX0pa2IEBg&q&nis=4&adurl&ved=2ahUKEwik79TiooaFAxU6HjQIHaZfC5QQ0Qx6BAgJEAE'
    // },
    // {
    //   name: "SubTo Community",
    //   icon: "bx bx-home-alt",
    //   show: true,
    //   link: 'https://www.subto.com/what-is-subto?gc_id=20292249549&h_ad_id=&gad_source=1&gclid=CjwKCAjwte-vBhBFEiwAQSv_xYo0NPZxoHXlecGErLxsIJHWhlA_Q4r-txuMbYhsX_yvbsnfNH872BoCgX8QAvD_BwE'
    // }
  ];

  // Routes
  const router = createBrowserRouter([
    {
      key: "home",
      path: "/",
      element: (
        <>
          <Home contactOptionListArr={contactOptionListArr}/>        
        </>
      ),
      errorElement: <NotFound />,
    }
  ]);

  return <RouterProvider router={router} />;
}

export default App;
