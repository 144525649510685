import ContactList from "./ContactList";
import Image from "./Image";

const Contact = ({ contactOptionListArr }) => {
  return (
    <>
        <div className="contact-card">
            <div className="wrap-img">
                <Image />
            </div>
            <div className="signature">
                <h2><b>Dylan Cunningham</b></h2>
                <h3>Owner of csweet Brands</h3>
                <h4>Investor | Lender | Real Estate</h4>
                <h5>Ask me about Money, Investing, Real Estate, Artificial Intelligence, and Data Science.</h5>
                <h4><b>FREE Resources Below</b></h4>
            </div>
            <ContactList contactOptionListArr={contactOptionListArr}/>
        </div>
    </>
  );
};

export default Contact;
